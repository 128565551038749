
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Sources',

  methods: {
    back () {
      this.$router.go(-1)
    }
  }
})
