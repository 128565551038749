<template>
<div class="wrapper">
<div class="wrapper_inner">
<div class="sources">
  <div class="button" v-on:click="back"></div>
  <h2>Quellen</h2>
  <p><sup>1</sup> Fachinformation, Stand Juni 2021;</p>
  <p><sup>2</sup>Hof, H. et al: Nitroxolin- eine Option zur antibiotischen Therapie von Harnwegsinfektionen. Urologe 2017.56:167-171;</p>
  <p><sup>3</sup>Naber, K. et al: Aktuelle Empfehlungen zur Antibiotika&shy;therapie von Harnwegsinfektionen. Uroscan 2015:57-77;</p>
  <p><sup>4</sup>Pfister, W. et al: Untersuchung zur Resistenz von Erregern bei Harnwegsinfektionen stationärer Patienten gegenüber Nitroxolin. Nieren – und Hochdruck&shy;krankheiten 44.Nr. 7/2015:294-298;</p>
  <p><sup>5</sup>Sobke A et al: Empirical treatment of lower urinary tract infections in the face of spreading multidrug resistance: in vitro study on the effectiveness of nitroxoline. Int J Anti&shy;microb Agents 2018; 51(2):213-220;</p>
  <p><sup>6</sup>AWMF Registernummer: 043/044, https://www.awmf.org/uploads/tx_szleitlinien/043-044l_S3_Harnwegsinfektionen_2017-05.pdf;</p>
  <p><sup>7</sup>AWMF-Register-Nr. 053-001, https://www.awmf.org/uploads/tx_szleitlinien/053-001l_S3_Brennen_beim_Wasserlassen_2018-09.pdf;</p>
  <p><sup>8</sup>Ölschläger, T.: Nitroxolin inhibiert in vitro die Adhärenz an und die Invasion von humanen Harnwegsepithelzellen durch uropathogene Bakterien. Bad Honnef-Symposium 2015. Königswinter, 30.-31.03.2015. Düsseldorf: German Medical Science GMS Publishing House; 2015. Doc15bhs19</p>

  <p><strong>Nitroxolin forte. Wirkstoff:</strong> Nitroxolin.</p>
  <p><strong>Zusammensetzung:</strong> 1 Weichkapsel enthält 250 mg Nitroxolin. Sonstige Bestandteile: Gelbes Wachs, hydriertes Soja&shy;öl, partiell hydriertes Sojaöl, entölte Phospholipide aus Sojabohnen, mittelkettige Tri&shy;glyceride, 3-Ethoxy-4-hydroxy&shy;benzaldehyd, 1-(4-Methoxyphenyl)ethanon, Gelatine, Glycerol, ger. Was&shy;ser, Titandioxid (E 171), Ponceau 4R (E 124). <strong>Anwendungsgebiete:</strong> Akute und chronische Infektionen der ableitenden Harnwege (z.B. Cystitis, Urethritis, Ureteritis) mit Nitroxolin&shy;empfindlichen Bakterien und Sprosspilzen. Rezidivprophylaxe. <strong>Gegenanzeigen:</strong> Überempf. ggü. Nitroxolin, Sojaöl, Ponceau 4R (E 124) oder sonst. Bestandteilen. Schwere Nieren- und Leberfunktionsstörungen. Strenge Indika&shy;tionsstellung in Schwangerschaft u. Stillzeit. <strong>Nebenwirkungen:</strong> <u>Häufig:</u> gastrointestinale Beschwer&shy;den (Übelkeit, Erbrechen, Diarrhoe). <u>Gelegentl.:</u> allergische Hauterscheinungen (Rötung, Jucken). Selten: ­allergische Blutbildveränderungen (Thrombozytopenie). <u>Sehr selten:</u> neurologische Nebenwirkungen (Müdigkeit, Kopfschmerz, Schwindel, Gangunsicherheit). <u>Sonstige:</u> Sojaöl und Ponceau 4R (E 124) kön&shy;nen in seltenen Fällen schwere allergi&shy;sche Reaktionen hervorrufen. <u>Hinweis:</u> Selten geringfügige Aus&shy;scheidung der Wirksubstanz mit dem Schweiß, dadurch vorübergehende Gelbfärbung von Haut, Haa&shy;ren, Nägeln möglich. Sehr selten vorübergehende Gelbfärbung der Skleren. <strong>Verschreibungspflichtig.</strong>
  Stand: Juni 2021.</p>
  <address><strong>Vertrieb:</strong> MIP Pharma GmbH, Kirkeler Str. 41, 66440 Blieskastel<br />
  <strong>Pharm. Unternehmer:</strong> Chephasaar, Chem.-pharm. Fabrik GmbH,<br />
  Mühlstr. 50, 66386 St. Ingbert<br />
  <strong>www.nitroxolin.de</strong></address>
</div>
</div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Sources',

  methods: {
    back () {
      this.$router.go(-1)
    }
  }
})
</script>
